<template>
  <div class="md:flex">
    <SideBar v-if="!hideSideBar" :data="routes" />
    <div :class="hideSideBar ? 'w-full' : 'md:ml-72 w-full'">
      <FreeTrialEndAlert 
        v-if="isFreeTrial" 
        :message="`You have ${ freeTrialRemainingDays } days remaining in your free trial. Ready to subscribe?`"
      />
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import SideBar from "@/components/general/SideBar.vue";
import FreeTrialEndAlert from "@/components/freeTrial/FreeTrialEndAlert.vue";
import { getFreeTrialData } from "@/services/user/user";

import {
  internalUserRoutes,
  generalUserRoutes,
  adminUserRoutes,
} from "@/utils/sideBarRoutes.config.js";

const store = useStore();
const router = useRouter();
const routes = ref(null);

const user = computed(() => store.getters["user/getUserData"]);

const isFreeTrial = ref(false);
const hideSideBar = ref(true);
const freeTrialRemainingDays = ref(0);

onMounted( async () => {
  await setFreeTrialData()

  if (user.value.role === "Mastermind_admin") routes.value = adminUserRoutes;
  else if (user.value.type === "Internal") routes.value = internalUserRoutes;
  else routes.value = generalUserRoutes;
});

async function setFreeTrialData() {
  try {
    const freeTrialData = await getFreeTrialData();

    if (freeTrialData.is_trial_active && !freeTrialData.has_trial_expired) {
      isFreeTrial.value = freeTrialData.is_trial_active;
      freeTrialRemainingDays.value = 3 - freeTrialData.days_elapsed;
      hideSideBar.value = false;
    } else if (freeTrialData.has_trial_expired) {
      store.commit("user/setIsFreeTrialExpired", true);
      router.replace("/free-trial-end");
      removeLastHistoryEntry()
    }

  } catch (error) {
    store.commit("user/setIsFreeTrialExpired", false);
    isFreeTrial.value = false;
    hideSideBar.value = false;
  }
}

function removeLastHistoryEntry() {
  window.history.pushState(null, '', window.location.href);
  window.history.go(1);
}
</script>
